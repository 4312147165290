import React from 'react'
import Layout from '../components/layouts/landing'
import Heading from '../components/Heading'
import Icon from '../components/Icon'
import IconImage from '../images/icon.svg'

const IndexPage = () => {
  return (
    <Layout title="wrkvbs">
      <div css={{ width: '100%', textAlign: 'center' }}>
        <Icon css={{ margin: '0 auto' }}>
          <IconImage />
        </Icon>
      </div>
      <Heading css={{ marginTop: 35 }}>Coming soon.</Heading>

    </Layout>
  )
}

export default IndexPage
